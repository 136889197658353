const schedule = [
  {
    "activity": "iosconfsg21.workshop1",
    "start_at": "2021-01-17T00:20:00+00:00",
    "end_at": "2021-01-17T00:45:00+00:00",
    "id": 1,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "registration",
    "title": "Check-in 💻"
  },
  {
    "activity": "iosconfsg21.workshop1",
    "start_at": "2021-01-17T00:45:00+00:00",
    "end_at": "2021-01-17T01:00:00+00:00",
    "id": 2,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "registration",
    "title": "Energy Boost ⚡️"
  },
  {
    "activity": "iosconfsg21.workshop1",
    "start_at": "2021-01-17T01:00:00+00:00",
    "end_at": "2021-01-17T03:00:00+00:00",
    "id": 3,
    "speaker_bio": "Daniel is the author of more than a dozen books including the best selling books A Functional Programming Kickstart, A SwiftUI Kickstart, A Swift Kickstart and Dear Elena. He presents iOS, Functional Programming, SwiftUI, and Swift training and consults through his company Dim Sum Thinking.",
    "speaker_company": "Dim Sum Thinking",
    "speaker_image": "daniel",
    "speaker_name": "Daniel H Steinberg",
    "speaker_twitter": "dimsumthinking",
    "talk_description": "This workshop focuses on the four main pillars of Combine: Publisher, Subscribers, Subscriptions, and Operators. Session-1 will cover the Fundamentals of Combine, Notifications, Subscribing with Sink, Republishing and more.",
    "talk_type": "workshop",
    "title": "Combine Workshop - Session 1"
  },
  {
    "activity": "iosconfsg21.workshop1",
    "start_at": "2021-01-17T03:00:00+00:00",
    "end_at": "2021-01-17T03:30:00+00:00",
    "id": 4,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "15 minutes break",
    "talk_type": "shortbreak",
    "title": "Break ☕ 🚻 💬"
  },
  {
    "activity": "iosconfsg21.workshop1",
    "start_at": "2021-01-17T03:30:00+00:00",
    "end_at": "2021-01-17T05:30:00+00:00",
    "id": 5,
    "speaker_bio": "Daniel is the author of more than a dozen books including the best selling books A Functional Programming Kickstart, A SwiftUI Kickstart, A Swift Kickstart and Dear Elena. He presents iOS, Functional Programming, SwiftUI, and Swift training and consults through his company Dim Sum Thinking.",
    "speaker_company": "Dim Sum Thinking",
    "speaker_image": "daniel",
    "speaker_name": "Daniel H Steinberg",
    "speaker_twitter": "dimsumthinking",
    "talk_description": "This workshop focuses on the four main pillars of Combine: Publisher, Subscribers, Subscriptions, and Operators. Session-2 will continue on SwiftUI, Joining Steams and go beyond URLSession, JSON, Errors in Combine.",
    "talk_type": "workshop",
    "title": "Combine Workshop - Session 2"
  },
  {
    "activity": "iosconfsg21.workshop2",
    "start_at": "2021-01-18T05:20:00+00:00",
    "end_at": "2021-01-18T05:45:00+00:00",
    "id": 6,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "registration",
    "title": "Check-in 💻"
  },
  {
    "activity": "iosconfsg21.workshop2",
    "start_at": "2021-01-18T05:45:00+00:00",
    "end_at": "2021-01-18T06:00:00+00:00",
    "id": 7,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "registration",
    "title": "Energy Boost ⚡️"
  },
  {
    "activity": "iosconfsg21.workshop2",
    "start_at": "2021-01-18T06:00:00+00:00",
    "end_at": "2021-01-18T08:00:00+00:00",
    "id": 8,
    "speaker_bio": "Vincent is a lead iOS developer, at Worldline, Lyon 🇫🇷 He started doing iOS development back in 2011 and for the last two years he has been working on the iOS apps of major French banks.",
    "speaker_company": "Worldline",
    "speaker_image": "vincent",
    "speaker_name": "Vincent Pradeilles",
    "speaker_twitter": "v_pradeilles",
    "talk_description": "Leveraging KeyPaths to their full potential 🤯",
    "talk_type": "workshop",
    "title": "Modern Swift Development Workshop - Session 1"
  },
  {
    "activity": "iosconfsg21.workshop2",
    "start_at": "2021-01-18T08:00:00+00:00",
    "end_at": "2021-01-18T08:30:00+00:00",
    "id": 9,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "15 minutes break",
    "talk_type": "shortbreak",
    "title": "Break ☕ 🚻 💬"
  },
  {
    "activity": "iosconfsg21.workshop2",
    "start_at": "2021-01-18T08:30:00+00:00",
    "end_at": "2021-01-18T10:30:00+00:00",
    "id": 10,
    "speaker_bio": "Vincent is a lead iOS developer, at Worldline, Lyon 🇫🇷 He started doing iOS development back in 2011 and for the last two years he has been working on the iOS apps of major French banks.",
    "speaker_company": "Worldline",
    "speaker_image": "vincent",
    "speaker_name": "Vincent Pradeilles",
    "speaker_twitter": "v_pradeilles",
    "talk_description": "Taking Property Wrappers to their limit 🚀",
    "talk_type": "workshop",
    "title": "Modern Swift Development Workshop - Session 2"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T00:00:00+00:00",
    "end_at": "2021-01-20T00:30:00+00:00",
    "id": 11,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "Welcome to iOS Conf SG 2021 🎉",
    "talk_type": "registration",
    "title": "Check-in 💻"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T00:30:00+00:00",
    "end_at": "2021-01-20T00:45:00+00:00",
    "id": 12,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "registration",
    "title": "Energy Boost ⚡️"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T00:45:00+00:00",
    "end_at": "2021-01-20T01:00:00+00:00",
    "id": 13,
    "speaker_bio": "Husband 💑 Father 👨‍👩‍👧‍👧 Dreamer 🦄 Organizer of @iOSConfSG",
    "speaker_company": "",
    "speaker_image": "subh",
    "speaker_name": "Subhransu Behera",
    "speaker_twitter": "subhransu",
    "talk_description": "We're back for our 6th conference. I will share the goal and passion of the organizers and how you all are helping us create an amazing community.",
    "talk_type": "openingAddress",
    "title": "Opening address"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T01:00:00+00:00",
    "end_at": "2021-01-20T01:35:00+00:00",
    "id": 14,
    "speaker_bio": "Malin is an indie developer working on an app called Orbit, and co-founder of Triple Glazed Studios. Being one half of a two-person team, Malin keeps herself busy developing apps and backends (using server-side Swift and Vapor), designing apps, product pages, icons, and other app artwork. Malin is also passionate about the development community and regularly organizes local meetups in Vancouver, Canada. She also chats about tech and indie development on the Cup of Tech podcast.",
    "speaker_company": "Orbit",
    "speaker_image": "malin",
    "speaker_name": "Malin Sundberg",
    "speaker_twitter": "malinsundberg",
    "talk_description": "Many of us are excited to start using SwiftUI; we’ve experimented with it, followed along with tutorials, maybe even used it to build a few widgets. So far, so good! At some point, we want to start using SwiftUI when building our apps. Since SwiftUI is a relatively new framework, we might come across some constraints when using it for fully-fledged apps. This talk is about using SwiftUI to build and ship complex apps for multiple Apple platforms. We will go through how we can structure our projects when building apps for multiple platforms, how to combine SwiftUI with AppKit and UIKit, how to work around some limitations, and how to put the new framework to its best use.",
    "talk_type": "normalTalk",
    "title": "The Hitchhiker’s Guide to SwiftUI"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T01:35:00+00:00",
    "end_at": "2021-01-20T02:10:00+00:00",
    "id": 15,
    "speaker_bio": "Ben is an iOS, Mac, and Ruby on Rails developer from Houston, Texas. Under his company, Fickle Bits, he builds apps for clients ranging from startups to established companies from multiple industries. He also runs NSScreencast.com, a site with over 400 videos on app development.",
    "speaker_company": "NSScreencast",
    "speaker_image": "ben",
    "speaker_name": "Ben Scheirman",
    "speaker_twitter": "subdigital",
    "talk_description": "We’ve all been there. We implement a Codable model and it fits the API just perfectly, and the magical automatic implementation provided by Swift just works and there’s so little code you have to write. But then we encounter something new, where things go off the rails and you have to implement the encode/decode methods yourself. This talk focuses on that part of Codable. We’ll start with a quick overview of the customization points and how the API works, then talk about how to solve problems with it.",
    "talk_type": "normalTalk",
    "title": "Advanced Codable"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T02:10:00+00:00",
    "end_at": "2021-01-20T02:20:00+00:00",
    "id": 16,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "10 minutes discussion",
    "talk_type": "shortbreak",
    "title": "Participant Discussions 💬"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T02:20:00+00:00",
    "end_at": "2021-01-20T02:30:00+00:00",
    "id": 17,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "10 minutes break",
    "talk_type": "shortbreak",
    "title": "Short Break ☕ 🚻 💬"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T02:30:00+00:00",
    "end_at": "2021-01-20T02:40:00+00:00",
    "id": 18,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "registration",
    "title": "Energy Boost ⚡️"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T02:40:00+00:00",
    "end_at": "2021-01-20T03:15:00+00:00",
    "id": 19,
    "speaker_bio": "Dad first, iOS next. Co-author: iOS Programming Pushing the Limits. Wrote MKStoreKit, MKNetworkKit. Conf Speaker: Bluetooth SIG, iOS Conf SG, try! Swift.",
    "speaker_company": "Xero",
    "speaker_image": "mugunth",
    "speaker_name": "Mugunth Kumar",
    "speaker_twitter": "mugunthkumar",
    "talk_description": "We have had react like architectures/ TEA (The elm architecture) and various others (in addition to MVVM+Rx). However, continuous integration and deployment remains a pain that has not been solved very well. Different companies have different strategies and this talk explains one such that has been very successful in helping a company scale the operations from a one-man team to a team with 15 developers.",
    "talk_type": "normalTalk",
    "title": "A Micro-services perspective on iOS architecture"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T03:15:00+00:00",
    "end_at": "2021-01-20T03:50:00+00:00",
    "id": 20,
    "speaker_bio": "Madushan is a lead software engineer at Grab working on the mobile platform team.",
    "speaker_company": "Grab",
    "speaker_image": "madushan",
    "speaker_name": "Madushan Gamage",
    "speaker_twitter": "itsmadnik",
    "talk_description": "CocoaPods is the first dependency manager for Cocoa projects.  Today it is used in over 3 million apps. Carthage is another alternative dependency manager that arrived in 2014. Swift 3.0 release introduced a new way of managing Swift packages - Swift Package Manager(SPM).  After years of development, SPM is ready for its prime time.  This talk will be an exploration of why it is the time to start managing packages using SPM and how to make a smooth transition from third-party dependency management solutions to SPM.",
    "talk_type": "normalTalk",
    "title": "From CocoaPods to Swift Package Manager"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T03:50:00+00:00",
    "end_at": "2021-01-20T04:05:00+00:00",
    "id": 21,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "Sponsor hopping game",
    "talk_type": "shortbreak",
    "title": "The Sherlock in You 🕵️‍♂️🕵️‍♀️"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T04:05:00+00:00",
    "end_at": "2021-01-20T04:55:00+00:00",
    "id": 22,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "shortbreak",
    "title": "Grab a Bite 🍽 (50 Mins Break)"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T04:55:00+00:00",
    "end_at": "2021-01-20T05:05:00+00:00",
    "id": 23,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "Quiz and Games",
    "talk_type": "registration",
    "title": "Energy Boost ⚡️"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T05:05:00+00:00",
    "end_at": "2021-01-20T05:15:00+00:00",
    "id": 24,
    "speaker_bio": "Pavel Tarasevich is a senior iOS developer at Foodpanda, here in Singapore. He has 9+ years of iOS experience. Pavel is passionate about architectures and augmented reality.",
    "speaker_company": "foodpanda",
    "speaker_image": "pavel",
    "speaker_name": "Pavel Tarasevich",
    "speaker_twitter": "",
    "talk_description": "Live coding session where code will be enhanced with best VIPER practices.",
    "talk_type": "lightningTalk",
    "title": "VIPER architecture ⚡️"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T05:15:00+00:00",
    "end_at": "2021-01-20T05:50:00+00:00",
    "id": 25,
    "speaker_bio": "Rongyan is a mobile developer in Shopee. iOS lover. She likes to make friends on Github. github.com/sueLan",
    "speaker_company": "Shopee",
    "speaker_image": "Rongyan",
    "speaker_name": "Rongyan (蓝容) Zheng",
    "speaker_twitter": "ZoeZheng_RY",
    "talk_description": "It is quite a challenge to develop a super App with a huge amount of users. We've encountered crashes caused by invalid memory access from time to time. Although it is a bit hard to trace the source of this kind of crash, we can still get further clues by analyzing sections in crash reports.",
    "talk_type": "normalTalk",
    "title": "Explore invalid memory access"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T05:50:00+00:00",
    "end_at": "2021-01-20T06:00:00+00:00",
    "id": 251,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "10 minutes break",
    "talk_type": "shortbreak",
    "title": "Short Break ☕️ 🚻 💬"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T06:00:00+00:00",
    "end_at": "2021-01-20T06:35:00+00:00",
    "id": 26,
    "speaker_bio": "Maciej is a passionate iOS developer that pays much attention to details and code quality. He likes improving things, always looking for better solutions because he's amazed that in the programming world, issues can be solved in 100s of ways. In 2016 he kicked off with his friends the swifting.io blog, where he has been posting his thoughts about various topics.",
    "speaker_company": "Allegro",
    "speaker_image": "maciej",
    "speaker_name": "Maciej Piotrowski",
    "speaker_twitter": "paciej00",
    "talk_description": "Working on an application used by thousands of users might be hard. The app usually consists of multiple files with the source code and even thousands or millions of lines of code - it takes ages to compile. Splitting a monolith into modules and caching the binaries can speed up the build process!",
    "talk_type": "normalTalk",
    "title": "Speeding up the build process of a monolithic application"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T06:35:00+00:00",
    "end_at": "2021-01-20T06:45:00+00:00",
    "id": 38,
    "speaker_bio": "Jason is an iOS developer who is passionate about finding better software architecture and improving his development process so that he can deliver the best UX/UI. He enjoys discussing his approaches and other developers' experiences. Besides his interest in software, he also enjoys reading books about history.",
    "speaker_company": "OOZOU",
    "speaker_image": "jason",
    "speaker_name": "Jason Nam",
    "speaker_twitter": "seokhyeoneee",
    "talk_description": "Introducing industry-leading strategies to reduce UI-related bugs. Nowadays mobile apps not only consume data, but actively manipulate it: it’s critical to always match what users see to what they get.",
    "talk_type": "lightningTalk",
    "title": "If what you see is NOT what you get ⚡️"
  },
  {
    "activity": "iosconfsg21.day1",
    "start_at": "2021-01-20T06:45:00+00:00",
    "end_at": "2021-01-20T07:45:00+00:00",
    "id": 27,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "Stay tuned with the announcement in the morning!",
    "talk_type": "shortbreak",
    "title": "Closing with Games 🕵🏼‍♂️ 🕵🏼‍♀️ 🖱 with 🎁 "
  },

  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T04:20:00+00:00",
    "end_at": "2021-01-21T04:40:00+00:00",
    "id": 28,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "Welcome to iOS Conf SG 2021 🎉",
    "talk_type": "registration",
    "title": "Check-in 💻"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T04:40:00+00:00",
    "end_at": "2021-01-21T04:50:00+00:00",
    "id": 29,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "registration",
    "title": "Energy Boost ⚡️"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T04:50:00+00:00",
    "end_at": "2021-01-21T05:00:00+00:00",
    "id": 30,
    "speaker_bio": "iOS Tech Lead & Engineer, iOS Subject Matter Expert at GovTech. Apple Distinguished Educator.",
    "speaker_company": "GovTech",
    "speaker_image": "melvin",
    "speaker_name": "Melvin Tan",
    "speaker_twitter": "Melvin_tan",
    "talk_description": "iOS Community in Singapore and plans for 2021.",
    "talk_type": "openingAddress",
    "title": "Opening address"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T05:00:00+00:00",
    "end_at": "2021-01-21T05:35:00+00:00",
    "id": 31,
    "speaker_bio": "Kenneth works as a Principal Software Engineer at SPDigital. He is passionate about TDD and BDD. He likes to bring agile software practices into iOS development space. He is also a prominent speaker at iOS Dev Scout, especially on iOS topics like Testing and IPA Code Injection. He enjoys running and playing the piano while not thinking about failed tests.",
    "speaker_company": "SPdigital",
    "speaker_image": "kenneth",
    "speaker_name": "Kenneth Poon",
    "speaker_twitter": "",
    "talk_description": "XcodeSurgery is a Swift CLI tool that aims to eliminate unnecessary recompilations when building different app variants. With 4 simple steps, learn how you can employ XcodeSurgery onto your Xcode Projects to significantly reduce time to build multiple app variants in your project.",
    "talk_type": "normalTalk",
    "title": "XcodeSurgery: Eliminating Recompilation for your iOS Build Variants"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T05:35:00+00:00",
    "end_at": "2021-01-21T06:10:00+00:00",
    "id": 32,
    "speaker_bio": "Benoit is a French iOS engineer working at ShopBack, here in Singapore. He has previously worked on a number of international e-commerce apps in Europe and SouthEast Asia. When not blogging about mobile development and personal growth, he’s outside, discovering the best running routes in Singapore.",
    "speaker_company": "ShopBack",
    "speaker_image": "benoit",
    "speaker_name": "Benoit Pasquier",
    "speaker_twitter": "BenoitPasquier_",
    "talk_description": "Preparing and testing an app for localization can be really tedious and time consuming. In this talk, we will explore how we can take advantage of SwiftUI to structure an app for localization and test it in seconds.",
    "talk_type": "normalTalk",
    "title": "Localization in SwiftUI"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T06:10:00+00:00",
    "end_at": "2021-01-21T06:20:00+00:00",
    "id": 33,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "10 minutes discussion",
    "talk_type": "shortbreak",
    "title": "Participant Discussions 💬"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T06:20:00+00:00",
    "end_at": "2021-01-21T06:30:00+00:00",
    "id": 34,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "10 minutes break",
    "talk_type": "shortbreak",
    "title": "Short Break ☕️ 🚻 💬"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T06:30:00+00:00",
    "end_at": "2021-01-21T07:05:00+00:00",
    "id": 35,
    "speaker_bio": "Roxana is a Romanian mobile developer based in Dubai with a passion for augmented reality. She started coding when she was 13 years old and so far has been part of the development team of over 20 apps including one of the biggest digital public services app in Scandinavia. She is involved in the tech community, both online and offline at local events. As an advocate for women in tech, Roxana is encouraging other girls to join the tech world.",
    "speaker_company": "Nodes",
    "speaker_image": "roxana",
    "speaker_name": "Roxana Jula",
    "speaker_twitter": "roxanajula",
    "talk_description": "Augmented Reality is slowly but surely growing into one of the most exciting future techs. Apple is heavily investing into it and developing AR experiences has never been easier for iOS developers. This talk will cover: how to convert your existing models to USDZ with Reality Converter and how to make your experiences more interactive with Reality Composer.",
    "talk_type": "normalTalk",
    "title": "Intro to Apple’s Augmented Reality Tools: Reality Converter and Reality Composer"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T07:05:00+00:00",
    "end_at": "2021-01-21T07:40:00+00:00",
    "id": 36,
    "speaker_bio": "Patrick is a Senior iOS Engineer at Spotify in Stockholm where he works on build systems and developer experience",
    "speaker_company": "Spotify",
    "speaker_image": "patrick",
    "speaker_name": "Patrick Balestra",
    "speaker_twitter": "BalestraPatrick",
    "speaker2_company": "Spotify",
    "speaker2_image": "erick",
    "speaker2_name": "Erick Camacho",
    "speaker2_bio": "Erick works as a Senior iOS infrastructure engineer at Spotify taking care of the iOS developer experience.",
    "speaker2_twitter": "ecamacho",
    "talk_description": "We all share the pain of long compilation times. In this session, Patrick and Erick discuss how the soon-to-be open source tool, XCMetrics, has improved the developer experience for iOS engineers at Spotify and enabled teams to make data-informed decisions about their projects. Along with collecting detailed Xcode build metrics, attendees will get a demo of how developers can find accurately collected build times at a file, or even a function level, warnings, errors, and their machine specifications in order to understand and track the developer experience.",
    "talk_type": "combinedTalk",
    "title": "XCMetrics - The All-In-One Solution to Track Xcode Build Metrics"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T07:40:00+00:00",
    "end_at": "2021-01-21T07:50:00+00:00",
    "id": 37,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "10 minutes break",
    "talk_type": "shortbreak",
    "title": "Short Break ☕️ 🚻 💬"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T07:50:00+00:00",
    "end_at": "2021-01-21T08:25:00+00:00",
    "id": 38,
    "speaker_bio": "Alexander is a veteran software engineer from Germany, specialises in creating awesome apps for macOS, iOS, tvOS and watchOS using Swift, Objective-C and all kinds of native Apple frameworks. His work has been featured in Apple TV commercials and on Apple WWDC banners. He has also worked on an Apple Design Award winning app.",
    "speaker_company": "Sketch",
    "speaker_image": "alexander",
    "speaker_name": "Alexander Repty",
    "speaker_twitter": "arepty",
    "talk_description": "Reverse engineering existing frameworks and applications can reveal a lot about how others have solved certain problems, or reveal issues inside your own apps. This talk will get you started with everything you need to successfully reverse engineer frameworks and applications.",
    "talk_type": "normalTalk",
    "title": "Reverse-Engineering Driven Development"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T08:25:00+00:00",
    "end_at": "2021-01-21T08:40:00+00:00",
    "id": 39,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "Sponsor hopping game",
    "talk_type": "shortbreak",
    "title": "The Sherlock in You 🕵️‍♂️🕵️‍♀️"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T08:40:00+00:00",
    "end_at": "2021-01-21T09:00:00+00:00",
    "id": 40,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "shortbreak",
    "title": "Grab a Bite 🍽 (20 Mins Break)"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T09:00:00+00:00",
    "end_at": "2021-01-21T09:10:00+00:00",
    "id": 42,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "registration",
    "title": "Are You Sherlock? - Compete for the top 3 spots 🏅"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T09:10:00+00:00",
    "end_at": "2021-01-21T09:45:00+00:00",
    "id": 43,
    "speaker_bio": "Paul is the author of Hacking with Swift, Pro Swift, Swift Design Patterns, Server-Side Swift, Hacking with macOS, Hacking with watchOS, Hacking with tvOS, Swift Coding Challenges, and more. Suffice it to say, he quite likes Swift. And coffee. (But mostly Swift.) (And coffee.)",
    "speaker_company": "Hacking with Swift",
    "speaker_image": "paul",
    "speaker_name": "Paul Hudson",
    "speaker_twitter": "twostraws",
    "talk_description": "Once the novelty of SwiftUI has passed, you'll soon realize the curse of Massive View Controllers has made it over to SwiftUI in the form of Massive Views. In this hands-on coding session, Paul will walk through five simple tips you can use in your project to make your code easier to read and write, and easier to maintain too.",
    "talk_type": "normalTalk",
    "title": "5 Steps to Better SwiftUI Views"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T09:45:00+00:00",
    "end_at": "2021-01-21T10:20:00+00:00",
    "id": 44,
    "speaker_bio": "",
    "speaker_company": "PSPDFKit",
    "speaker_image": "peter",
    "speaker_name": "Peter Steinberger",
    "speaker_twitter": "steipete",
    "talk_description": "At PSPDFKit, we don't have a separate team for CI/CD, our engineering team also owns testing and delivery. In this talk I'll give you an overview of the options today, and will explore what path we took for fast, automated testing in a multi-platform environment. We'll also talk about money, because running cost is an important topic for bootstrapped companies. Lastly, we will look at options around configuration management tools to automate machine setups.",
    "talk_type": "normalTalk",
    "title": "Continuous Integration for the Rest of Us"
  },
  {
    "activity": "iosconfsg21.day2",
    "start_at": "2021-01-21T10:15:00+00:00",
    "end_at": "2021-01-21T11:15:00+00:00",
    "id": 45,
    "speaker_bio": "iOS Conf SG and iOS Dev Scout Organiser Team",
    "speaker_company": "iOSConfSG",
    "speaker_image": "organiser",
    "speaker_name": "Organiser",
    "speaker_twitter": "iosconfsg",
    "talk_description": "",
    "talk_type": "shortbreak",
    "title": "Closing + Virtual After-party 🍹 🍻 🧉"
  }
]

export default schedule
